<template>
    <div>
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
            <div class="mx-25">
                <a-row type="flex" :gutter="24">
                    <a-col :span="24" class="text-right">
                        <a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
                            @input="debouncedSearch" />
                    </a-col>
                </a-row>
            </div>

            <a-table class="mt-20" :columns="columns" :data-source="reports" rowKey="id"
                :pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
                :loading="isNotLoading">

                <template slot="description" slot-scope="description">{{ description === "" ? "N/A" :
                    shortenString(description, 20)
                    }}</template>


                <template slot="show" slot-scope="action">
                    <a-button type="primary" @click="$router.push(`/reports/show/${action}`)">
                        <a-icon type="eye" />
                    </a-button>
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
import reportAPI from '../../api/report';
import debounce from 'lodash/debounce';
// Table columns
const columns = [
    {
        title: 'Type Of Reports',
        dataIndex: 'type.name',
        sorter: (a, b) => a.type > b.type ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'type.name' },
    },
    {
        title: 'Contact Email',
        dataIndex: 'contact_email',
        sorter: (a, b) => a.contact_email > b.contact_email ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'contact_email' },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        sorter: (a, b) => a.description > b.description ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'description' },
    },
    {
        title: 'For Customer',
        dataIndex: 'for_customer.slug',
        sorter: (a, b) => a.for_customer.slug > b.for_customer.slug ? 1 : -1,
        sortDirections: ['descend', 'ascend'],
        scopedSlots: { customRender: 'for_customer.slug' },
    },
    {
        title: 'Show',
        dataIndex: 'id',
        scopedSlots: { customRender: 'show' },
    },
];

export default {
    data() {
        return {

            columns,

            reports: [],

            pageSize: 10,

            currentPage: 1,

            totalCount: 0,

            query: '',

            selectedRowKeys: [],

            isNotLoading: false,

            debouncedSearch: () => { },

        }
    },
    computed: {

        // CSV data array
        csvData() {
            return this.authors.map(item => ({
                "Id": "%23" + item.key,
                "Date": item.date,
                "Status": item.status,
                "Customer": item.customer.name,
                "Product": item.product,
                "Revenue": "$" + item.revenue,
            }));
        }

    },
    methods: {
        shortenString(str, maxLength) {
            if (str == undefined) {
                return 'N/A';
            }
            if (str.length > maxLength) {
                return str.substring(0, maxLength) + "...";
            } else {
                return str;
            }
        },
        async handlePageChange(page) {
            this.currentPage = page;
            const data = await reportAPI.getReports({
                params: {
                    "limit": this.pageSize,
                    "skip": (this.currentPage - 1) * this.pageSize,
                }
            })
            if (data.reports) {
                this.reports = data.reports;
            }
            if (Object.keys(data).length !== 0) {
                this.totalCount = data.count;
            } else {
                this.totalCount = 0;
            }
            this.isNotLoading = false;
        },


        async onSearchChange() {
            if (this.query.length >= 3) {
                const data = await reportAPI.getReports({
                    params: {
                        "limit": this.pageSize,
                        "skip": 0,
                        "query": this.query,
                    }
                })
                if (data.code == "SUCCESS") {
                    if (data.data.reports) {
                        this.reports = data.data.reports;
                        this.totalCount = data.data.count;
                    }
                }
                this.isNotLoading = false;
            } else {
                const originalData = await reportAPI.getReports({
                    params: {
                        "limit": this.pageSize,
                        "skip": 0,
                    }
                });
                if (originalData.code == "SUCCESS") {
                    if (originalData.data.reports) {
                        this.reports = originalData.data.reports;
                        this.totalCount = originalData.data.count;
                    }
                }
                this.isNotLoading = false;
            }
        },


        // Export table in CSV format.
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join("|"),
                ...arrData.map(item => Object.values(item).join("|"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "muse-dashboard-csv.csv");
            link.click();
        }
    },
    async mounted() {
        this.debouncedSearch = debounce(this.onSearchChange, 300);
        const data = await reportAPI.getReports({
            params: {
                "limit": this.pageSize,
                "skip": (this.currentPage - 1) * this.pageSize,
            }
        })
        if (data.code == "SUCCESS") {
            if (data.data.reports) {
                this.reports = data.data.reports;
                this.totalCount = data.data.count;
            }
        }
        this.isNotLoading = false;
    },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
    display: flex;
    align-items: center;
}

.table-avatar-info .ant-avatar {
    margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
    font-size: 12px;
}

.btn-status::v-deep .anticon {
    line-height: 0;
}
</style>
